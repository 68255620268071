import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import { Link, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import Container from "react-bootstrap/Container"
import * as queryString from "query-string"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./page.css"

export const query = graphql`
  query PageDataQuery($slug: String) {
    strapiPage(link_id: { eq: $slug }) {
      title
      content
      link_id
    }
  }
`
const SecondPage = ({ data }) => {
  const pageData = data.strapiPage
  return (
    <Layout className="mt-5 page">
      <SEO title={pageData.title} />
      <br></br>
      <Container className="my-5 border bg-light">
        <h1 class="headline mt-3">{pageData.title}</h1>
        <hr class="my-3" />
        <ReactMarkdown renderers={{ image: Image }}>
          {pageData.content}
        </ReactMarkdown>
      </Container>
    </Layout>
  )
}
function Image(props) {
  return <img {...props} style={{ maxWidth: "100%" }} />
}
export default SecondPage
